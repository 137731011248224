import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"

const Icon = styled.img`
  height: 30px;
  width: 30px;
`

const Footer = styled.footer`
  position: absolute;
  bottom: 20px;
  left: 0;
  width: 100%;
  height: 30px;
  text-align: center;
  & img {
    margin: 0 10px;
  }
`

const footer = () => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
      linkedin: file(relativePath: { eq: "linkedin.svg" }) {
        publicURL
      }
      github: file(relativePath: { eq: "github.svg" }) {
        publicURL
      }
      spotify: file(relativePath: { eq: "spotify.svg" }) {
        publicURL
      }
    }
  `)

  return (
    <Footer>
      <a
        href="https://www.linkedin.com/in/kevin-davis-770b6a83/"
        title="LinkedIn"
      >
        <Icon src={data.linkedin.publicURL} />
      </a>
      <a href="https://github.com/kevindavi5/" title="GitHub">
        <Icon src={data.github.publicURL} />
      </a>
      <a
        href="https://open.spotify.com/user/1236313213?si=Ve1Cbk68QaCSMZYuanAc6A"
        title="Spotify"
      >
        <Icon src={data.spotify.publicURL} />
      </a>
    </Footer>
  )
}

export default footer
